import * as React from 'react';
import Markdown from 'react-markdown';

const PackagesContainer = ({ packages }) => (
  <>
    <div
      className={`grid grid-cols-1 ${
        packages.length < 4 ? (packages.length < 2 ? 'lg:grid-cols-2' : 'lg:grid-cols-3') : 'lg:grid-cols-4'
      } gap-8`}
    >
      {packages.map((el, i) => (
        <div key={i}>
          <div className="text-lg mb-2.5">Pakiet</div>
          <div className="text-5xl text-blue-600 mb-2.5">{el.hours}</div>
          <div className={`text-lg ${el.description && 'mb-5'}`}>{el.subtitle}</div>
          {el.description && <Markdown className="w-full markdown">{el.description}</Markdown>}
        </div>
      ))}
    </div>
  </>
);

export default PackagesContainer;
