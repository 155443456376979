import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Markdown from 'react-markdown';
import LayoutContainer from '../containers/layout';
import PageBackgroundImageComponent from '../components/page-background-image';
import PageContentComponent from '../components/page-content';
import PageTitleComponent from '../components/page-title';
import DividerComponent from '../components/divider';
import ButtonSeeAlsoComponent from '../components/button-see-also';
import TestsContainer from '../containers/tests';
import PackagesContainer from '../containers/packages';

const GeneralEnglishPage = () => {
  const data = useStaticQuery(query);

  return (
    <LayoutContainer title={data.strapiGeneralEnglish.title} description={data.strapiGeneralEnglish.description}>
      <PageTitleComponent showBackground={true}>
        <div className="text-4xl lg:text-5xl text-black">{data.strapiGeneralEnglish.title}</div>
      </PageTitleComponent>

      <PageBackgroundImageComponent image={data.strapiGeneralEnglish.backgroundImage.localFile.childImageSharp.gatsbyImageData} />

      <PageContentComponent showBackground={true}>
        <section className="mb-12">
          <div className="flex">
            <div className="w-full lg:w-3/5 lg:pr-20">
              <div className="w-full text-4xl text-blue-600 mb-12">{data.strapiGeneralEnglish.subtitle}</div>
              <Markdown className="no-widows w-full markdown">{data.strapiGeneralEnglish.description}</Markdown>
            </div>
            <div className="relative hidden lg:block w-2/5 pl-20 pt-4">
              <div className="absolute -top-16 left-0 w-0.5 h-36 bg-gray-200"></div>
              <div className="henderson-font text-black text-xs font-semibold mb-4">ZOBACZ TEŻ:</div>
              <Link to="/angielski-konwersacje">
                <ButtonSeeAlsoComponent title="Angielski Konwersacyjny" />
              </Link>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section className="w-full lg:w-3/5 mb-12 lg:pr-20">
          <Markdown className="no-widows markdown mb-8">{data.strapiGeneralEnglish.groupDescription}</Markdown>
        </section>

        <section className="mb-12">
          <PackagesContainer packages={data.strapiGeneralEnglish.packages} />
        </section>

        <section className="w-full lg:w-3/5 mb-12 lg:pr-20">
          <Markdown className="no-widows markdown mb-12">{data.strapiGeneralEnglish.packageDescription}</Markdown>
          <div className="w-full h-0.5 bg-gray-200 mb-8"></div>
          <Markdown className="no-widows markdown">{data.strapiGeneralEnglish.packageNotes}</Markdown>
        </section>

        <section className="mb-12">
          <DividerComponent />
        </section>

        <section>
          <TestsContainer tests={data.strapiGeneralEnglish.tests} />
        </section>
      </PageContentComponent>
    </LayoutContainer>
  );
};

const query = graphql`
  query {
    strapiGeneralEnglish {
      title
      subtitle
      description
      groupDescription
      packageDescription
      packageNotes
      backgroundImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO, quality: 92)
          }
        }
      }
      tests {
        type
        file {
          url
        }
      }
      packages {
        hours
        subtitle
        description
      }
    }
  }
`;

export default GeneralEnglishPage;
