import * as React from 'react';
import ButtonGoldBlackComponent from '../components/button-gold-black';

const TestsContainer = ({ tests }) => (
  <>
    <div className="text-2xl lg:text-4xl text-blue-600 mb-8">Pobierz test poziomujący</div>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {tests.map((el, i) => (
        <a key={i} target="_blank" href={el.file.url} rel="noreferrer">
          <ButtonGoldBlackComponent
            key={i}
            title={el.type === 'adults' ? 'Dorośli' : 'Młodzież'}
            subtitle="Pobierz plik w formacie DOC"
            animateArrow={true}
          />
        </a>
      ))}
    </div>
  </>
);

export default TestsContainer;
