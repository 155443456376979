import * as React from 'react';
import DividerComponent from './divider';

const PageContentComponent = ({ showBackground, children }) => (
  <div className="relative z-10 bg-white">
    {showBackground && (
      <div className="container mx-auto px-6">
        <DividerComponent className="bg-blue-600" />
      </div>
    )}
    <div className={`container mx-auto px-6 pb-24 ${showBackground && 'pt-16'}`}>{children}</div>
  </div>
);

export default PageContentComponent;
